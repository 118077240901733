<template>
<div class="Signup">
    <div class="popup">
        <div class="popup-container">
            <a @click="closeSignupPop()" class="cls"><div class="cls-btn">X</div></a>
            <div class="content">
                <h1>Create an Account</h1>
                <p>Get an amazing experience with creating an account, please continue to signup.</p>
                <div class="image">
                    <img src="../svg/Artboard.png" alt="">
                </div>
                <form v-on:submit="signup()" class="form">
                    <div class="fields">
                        <div class="field">
                            <label>First Name</label>
                            <input type="text" v-model="userInfo.first_name" required>
                        </div>
                    </div>
                    <div class="fields">
                        <div class="field">
                            <label>Last Name</label>
                            <input type="text" v-model="userInfo.last_name" required>
                        </div>
                    </div>
                    <!-- <div class="fields">
                        <div class="field">
                            <label>Phone No</label>
                            <input type="email" v-model="userInfo.phone" required>
                        </div>
                    </div> -->
                    <div class="fields">
                        <div class="field">
                            <label>Email</label>
                            <input type="email" v-model="userInfo.email" required>
                        </div>
                    </div>
                    <div class="fields">
                        <div class="field">
                            <label>Password</label>
                            <input type="password" v-model="userInfo.password" required>
                        </div>
                    </div>
                    <p v-if="$store.state.error" style="color:red">Email already registered</p>
                    <button type="submit">SignUp</button>
                    <a @click="openSigninPop()" class="signup">Already an account? <span> Signin </span></a>
                    <a @click="changeRoute('#/content/terms-and-conditions')" class="term">By signing up, you are agree with our <span> Terms & Conditions</span></a>
                </form>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'
export default {
    data(){
        return{
            userInfo:{},
            orders:{},
            error: false
        }
    },
    created(){
        this.userInfo = Object.assign({
            event: this.$store.state.event,
            table: "users"
        });        
    },
    methods:{
        openSigninPop(){
            document.getElementById('signupContainer').classList.remove('showSignupContainer'); 
            document.getElementById('signinContainer').classList.add('showSigninContainer'); 
        },
        closeSignupPop(){
            document.getElementById('signupContainer').classList.remove('showSignupContainer'); 
        },
        signupShopify(){
            var customer = {
                "first_name": this.userInfo.first_name,
                "last_name": this.userInfo.last_name,
                "email": this.userInfo.email,
                "phone": "555-1212",
                "verified_email": true,
                "addresses": [
                    {
                        "address1": "123 Oak St",
                        "city": "Ottawa",
                        "province": "ON",
                        "phone": "555-1212",
                        "zip": "123 ABC",
                        "last_name": "Lastnameson",
                        "first_name": "Mother",
                        "country": "CA"
                    }
                ]
            }
            axios
            .post("http://localhost:3000/create_user", {
                customer
            })
            .then(response => {
                if (response.data.name == "HTTPError") {
                    this.error = true;
                } else {
                this.userInfo.customer_id =
                response.data.default_address.customer_id;
                // this.$store.dispatch("setUser", this.items.user);
                // this.$router.push({ path: "/shipping" });
                }
            });
        },
        signup(e){
            event.preventDefault()
            this.$store.dispatch("SignUp", this.userInfo).then(response => {
                // console.log(response)
                setTimeout(() => {
                    if(!this.$store.state.error){
                        this.closeSignupPop()
                        this.$router.push({path:'/page/myaccount'})
                    }
                }, 500);
            })
        },
        changeRoute(path){
            document.getElementById('signupContainer').classList.remove('showSignupContainer'); 
            this.$router.push({path: path})
        },
    }
}
</script>

<style scoped>
.Signup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #000000a1;
    z-index: 9999;
    display: none;
}
.Signup .popup{
    /* position: absolute; */
    /* top: 10%; */
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100vh;
}
.Signup .popup .popup-container{
    width: 375px;
    background: #F3F2F5;
    border-radius: 15px;
    /* border: 1px solid  #707070; */
    padding: 10px;
    overflow: scroll;
    box-shadow: 0px 1px 3px #00000054;
}
.Signup .cls{
    /* top: -25px;
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 390px;
    font-size: 18px;
    left: 10px;
    margin-bottom: -25px; */
}
.Signup .cls-btn{
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #F3F2F5;
    box-shadow: 0px 1px 3px #00000054;
}
.Signup .popup .popup-container .content{
    width: 80%;
    margin: 20px auto;
}
.Signup .content h1{
    margin: 0;
    font-size: 20px;
}
.Signup .content p{
    margin: 0;
    font-size: 12px;
    color: #7B859A;
}
.Signup .popup .image{
    width: 50%;
    margin: 35px auto;
}
.popup .image img{
    width: 100%;
}
.Signup .form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Signup .fields{
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    color: #A2A2A2;
    border-radius: 5px;
}
.Signup .fields:hover, .fields:focus{
    background: #F3F2F5;
    box-shadow: 0px 1px 3px #00000054;
}
.Signup .icon{
    width: 40px;
    display: flex;
    justify-content: center;
}
.Signup .icon i{
    font-size: 20px;
}
.Signup .field{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.Signup .field label{
    font-size: 12px;
}
.Signup .field input{
    padding: 5px;
    border: none;
    background: transparent;
    border-bottom: 1px solid #A2A2A2;
    border-radius: 5px;
}
.Signup .field input:focus{
    outline: none;
    border-bottom: 2px solid #0F8FE3;
}
.Signup .field input:active~.field label{
    border-bottom: 1px solid;
}
.Signup .form button{
    width: 100%;
    padding: 10px 10px;
    border-radius: 5px;
    background: #ae97a2;
    border: 0;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    margin-top: 15px;
}
.Signup .form button:focus{
    outline: none;
}
.Signup .form button:hover{
    box-shadow: 0px 1px 3px #00000054;
}
.Signup .signup{
    text-align: center;
    width: 100%;
    font-size: 14px;
    color: #7B859A;
    margin-top: 30px
}
.Signup .signup span {
    color: #ae97a2;
    text-decoration: underline;
    font-weight: 500;
}
.Signup .term{
    text-align: center;
    width: 100%;
    font-size: 10px;
    color: #7B859A;
    margin-top: 20px
}
.Signup .term span{
    color: #ae97a2;
    text-decoration: underline;
}
@media (max-width: 560px) and (max-width: 760px){
    .Signup .cls{
        /* top: 0px;
        left: -20px; */
    }
    .Signup .popup .popup-container{
        width: 100%;
        background: #fff;
        border-radius: 0px;
        padding: 10px;
        /* height: 100%; */
        overflow: scroll;
        box-shadow: 0px 1px 3px #00000054;
    }
}
</style>